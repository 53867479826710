<header class="header-area">
  <div class="container">
    <div class="logo pull-left">
      <a routerLink="/">
        <img src="./assets/img/resources/logo.png" width="60" alt="Hamco Logo"> <span>Hamco Industries</span>
      </a>
    </div>
    <div class="top-info pull-right hideMobile">
      <ul>
        <li class="single-info-box">
          <div class="icon-holder">
            <span class="flaticon-earth-grid"></span>
          </div>
          <div class="text-holder">
            <p><span>Address:</span>
              Hamco Industries Pvt. Ltd. <br> G.T. Road Maksudan Jalandhar, 144008
            </p>
          </div>
        </li>
        <li class="single-info-box">
          <div class="icon-holder">
            <span class="flaticon-email-envelope-back-symbol-on-phone-screen"></span>
          </div>
          <div class="text-holder">
            <p><span>Call Us:</span> 0181-2708147<br>hamcoindia@yahoo.com</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</header>

<section class="mainmeu-area stricky">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="mainmenu-bg clearfix">
          <nav class="main-menu pull-left">
            <div class="navbar-header">
              <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>
            <div class="navbar-collapse collapse clearfix">
              <ul class="navigation">
                <li data-toggle="collapse" data-target=".navbar-collapse" [routerLink]="['/']"
                  [routerLinkActive]="['current']" [routerLinkActiveOptions]="{exact: true}">
                  <a routerLink="/">Home</a>
                </li>
                <li [routerLink]="['/about']" [routerLinkActive]="['current']" data-toggle="collapse"
                  data-target=".navbar-collapse"><a routerLink="/about">About</a></li>
                <li class="dropdown pointer" [routerLinkActive]="['current']">
                  <a class="dropdown-toggle" data-toggle="dropdown" id="productsDropdown">Products</a>
                  <ul class="dropdown-menu no-border" data-toggle="collapse" data-target=".navbar-collapse">
                    <li><a [routerLink]="['/products']" (click)="hideProductsDropdown()">Products</a></li>
                    <li><a [routerLink]="['/standards']" (click)="hideProductsDropdown()">Standards</a></li>
                  </ul>
                </li>
                <li [routerLink]="['/process']" [routerLinkActive]="['current']" data-toggle="collapse"
                  data-target=".navbar-collapse"><a routerLink="/process">Process</a>
                </li>
                <li [routerLink]="['/buy-sell']" [routerLinkActive]="['current']" data-toggle="collapse"
                  data-target=".navbar-collapse"><a routerLink="/buy-sell">Buy And
                    sell</a></li>
                <li [routerLink]="['/carrer']" [routerLinkActive]="['current']" data-toggle="collapse"
                  data-target=".navbar-collapse"><a routerLink="/carrer">Career</a></li>
                <li [routerLink]="['/library']" [routerLinkActive]="['current']" data-toggle="collapse"
                  data-target=".navbar-collapse"><a routerLink="/library">Library</a>
                </li>
                <li [routerLink]="['/contact']" [routerLinkActive]="['current']" data-toggle="collapse"
                  data-target=".navbar-collapse"><a routerLink="/contact">Contact</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>
