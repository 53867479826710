<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
    <div class="container text-center">
      <h1>Library</h1>
    </div>
</section>

<section class="breadcrumb-bottom-area">
    <div class="container">
        <div class="left pull-left">
        <a routerLink="/about">Library</a>
        </div>
        <div class="right pull-right">
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
            <li>Library</li>
        </ul>
        </div>
    </div>
</section>

<section class="welcome-industry-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-xs-12">
                <div class="content">
                    <div class="sec-title">
                        <p>Hamco Industries</p>
                        <h1>Library</h1>
                    </div>
                    <p>Includes links to the PDF documents which help in understanding the working of our industry </p>
                    <p><a href="https://www.isibang.ac.in/~library/onlinerz/resources/Enghandbook.pdf" target="_blank">https://www.isibang.ac.in/~library/onlinerz/resources/Enghandbook.pdf</a></p>
                    <p><a href="http://www.brchina.com/downloads/english/Rolling_Mill_Rolls.pdf" target="_blank">http://www.brchina.com/downloads/english/Rolling_Mill_Rolls.pdf</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
