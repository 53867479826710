<!--Start rev slider wrapper-->
<section class="rev_slider_wrapper">
  <div id="slider1" class="rev_slider myslideropacity" data-version="5.0">
    <ul>
      <li data-transition="slidingoverlayleft">
        <img src="./assets/img/slides/Network.jpg" alt="" width="1920" height="580" data-bgposition="top center" data-bgfit="cover"
          data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption tp-resizeme integrityslide" data-x="right" data-hoffset="0" data-y="center" data-voffset="-4"
          data-transform_idle="o:1;"
          data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
          data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
          data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none" data-start="500">
          <div class="slide-content-box mx-2">
            <h1>Integrity</h1>
            <p>We believe that integrity is the core of every <br>relationship  in keeping which we
              never compromise<br> on values.</p>
          </div>
        </div>
      </li>
      <li data-transition="slidingoverlayleft">
        <img src="./assets/img/slides/Products.jpg" alt="" width="1920" height="580" data-bgposition="top center" data-bgfit="cover"
          data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption tp-resizeme qualityproducts" data-x="left" data-hoffset="0" data-y="top" data-voffset="125"
          data-transform_idle="o:1;"
          data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
          data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
          data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none" data-start="700">
          <div class="slide-content-box">
            <h1>Quality Products</h1>
            <p>The very foundation of our business is <br> providing quality products to our clients.
            </p>
          </div>
        </div>
      </li>
      <li data-transition="slidingoverlayleft">
        <img src="./assets/img/slides/quality.png" alt="" width="1920" height="580" data-bgposition="top center" data-bgfit="cover"
          data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption tp-resizeme clientslide" data-x="center" data-hoffset="0" data-y="center" data-voffset="-80"
          data-transform_idle="o:1;"
          data-transform_in="x:[175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
          data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
          data-mask_in="x:[-100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none"
          data-responsive_offset="on" data-start="1500">
          <div class="slide-content-box">
            <h1>Clients</h1>
            <br>
          </div>
        </div>
        <div class="tp-caption tp-resizeme" data-x="center" data-hoffset="0" data-y="center" data-voffset="5"
          data-transform_idle="o:1;"
          data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
          data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-splitin="none"
          data-splitout="none" data-responsive_offset="on" data-start="2300">
          <div class="slide-content-box last-slide">
            <p class="text-center"> Our associates from all over the country <br>are our most valued assets.</p>
          </div>
        </div>
      </li>
      <li data-transition="slidingoverlayleft">
        <img src="./assets/img/slides/CSR.jpg" alt="" width="1920" height="580" data-bgposition="top center" data-bgfit="cover"
          data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption  tp-resizeme qualityproducts" data-x="left" data-hoffset="0" data-y="top" data-voffset="125"
          data-transform_idle="o:1;"
          data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
          data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
          data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none" data-start="700">
          <div class="slide-content-box">
            <h1>CSR</h1>
            <p>Giving back to society has been our forever aim,<br> and we make sure we fulfill our
              responsibility<br> towards the community and the environment.</p>
          </div>
        </div>
      </li>
      <li data-transition="slidingoverlayleft">
        <img src="./assets/img/slides/timely-delivery.jpg" alt="" width="1920" height="580" data-bgposition="top center" data-bgfit="cover"
          data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption tp-resizeme clientslide" data-x="center" data-hoffset="0" data-y="center" data-voffset="-80"
          data-transform_idle="o:1;"
          data-transform_in="x:[175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
          data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
          data-mask_in="x:[-100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none"
          data-responsive_offset="on" data-start="1500">
          <div class="slide-content-box">
            <h1>Timely Delivery</h1>
          </div>
        </div>
        <div class="tp-caption tp-resizeme" data-x="center" data-hoffset="0" data-y="middle" data-voffset="5"
          data-transform_idle="o:1;"
          data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
          data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-splitin="none"
          data-splitout="none" data-responsive_offset="on" data-start="2300">
          <div class="slide-content-box last-slide mino">
            <p class="text-center mt-4">Making on-time delivery is not only <br>a routine but a ritual at Hamco.
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</section>
<!--End rev slider wrapper-->

<!--Start services area-->
<section class="services-area" style="display: none;">
  <div class="container">
    <div class="sec-title">
      <p>What Do We Provide You</p>
      <h1>Our Products</h1>
    </div>
    <div class="row">
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/Steel-ingot.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" routerLink="/products">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Steel Ingots</h3>
              <p>
                Steel Ingots in section 3.5”x 4.5”; 4.5”x 5.0” and 5.5” x 6.5”
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/Roll-bars.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" routerLink="/products">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Rolled Bars</h3>
              <p>
                we are engaged in manufacturing of a wide range of Rolled Bars
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/Bright-bars.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" routerLink="/products">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Bright Bars</h3>
              <p>
                Our bright steel bars are produced from Hot Rolled Bars/ Wire Rod Coils
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
    </div>
  </div>
</section>
<!--End services area-->

<!--Start about us area-->
<section class="about-us-area" style="display: none;">
  <div class="outer-box clearfix">
    <div class="image-column" style="background-image:url(assets/img/about/bg-image.jpg);">
      <ul class="icon-box" role="tablist">
        <li class="single-item active" data-tab-name="about">
          <a href="#about" aria-controls="about" role="tab" data-toggle="tab" class="clearfix">
            <div class="icon">
              <span class="flaticon-sign"></span>
            </div>
            <div class="content">
              <h2>About Us</h2>
            </div>
          </a>
        </li>
        <li class="single-item" data-tab-name="integrity">
          <a href="#integrity" aria-controls="integrity" role="tab" data-toggle="tab" class="clearfix">
            <div class="icon">
              <span class="flaticon-sign"></span>
            </div>
            <div class="content">
              <h2>Intergrity</h2>
            </div>
          </a>
        </li>
        <li class="single-item" id="csrheading" data-tab-name="csr">
          <a href="#csr" aria-controls="csr" role="tab" data-toggle="tab" class="clearfix">
            <div class="icon">
              <span class="flaticon-sign"></span>
            </div>
            <div class="content">
              <h2>CSR</h2>
            </div>
          </a>
        </li>
        <div id="csrlisting">
            <li class="single-item" data-tab-name="charity">
              <a href="#charity" aria-controls="charity" role="tab" data-toggle="tab" class="clearfix">
                <div class="icon">
                  <span class="flaticon-sign"></span>
                </div>
                <div class="content">
                  <p>
                    Hamco
                  </p>
                  <h2> Charitable Trust</h2>
                </div>
              </a>
            </li>
            <li class="single-item" data-tab-name="foundation">
              <a href="#foundation" aria-controls="foundation" role="tab" data-toggle="tab" class="clearfix">
                <div class="icon">
                  <span class="flaticon-sign"></span>
                </div>
                <div class="content">
                  <p>
                    Hamco
                  </p>
                  <h2>Foundation</h2>
                </div>
              </a>
            </li>
        </div>
      </ul>
    </div>
    <div class="content-column tab-content">
      <!--Start single tab content-->
      <div class="inner-box tab-pane fade in active" id="about">
        <div class="sec-title">
          <p>Welcome to Hamco Industries</p>
          <h1>About Us</h1>
        </div>
        <div class="text-box">
          <p>
            Hamco Industries Private Limited, the name that you know today has a rich and colorful past
            all of which has helped us become the institution we are today. Hamco Group of Companies was
            founded by Late Sh. Om Prakash Gupta in the year 1952. The initial business profile of Hamco
            entailed manufacturing Fasteners which were mainly imported at the time. But as time passed,
            the business grew and Hind Auto Manufacturing Co. was established in the year 1956.
          </p>
          <p>
            Keeping in sync with the progress, the need for a new establishment was realized a little
            after the establishment of Hamco Auto. The need now was of an organization that would
            enhance future expansions of the business through forward integration.
          </p>
          <a class="thm-btn yellow-bg" routerLink="/about" style="padding: 5px 15px;">Read More</a>
        </div>
      </div>
      <!--End single tab content-->
      <!--Start single tab content-->
      <div class="inner-box tab-pane fade active" id="integrity">
        <div class="sec-title">
          <p>Company</p>
          <h1>Integrity</h1>
        </div>
        <div class="text-box">
          <p>At Hamco, integrity is the core of every relationship. We treat our employees, customers,
            partners, and suppliers with priority, respect, and fairness. Not only do we imbibe this in
            our practice but we expect the same in return. From day one, our employees are instructed to
            act ethically in all business dealings. For us, integrity is not a virtue which is adhered
            to commonly rather it is ritual which is practiced ALWAYS.
          </p>
          <p>
            Integrity is one of our foundational principles and to put it straightforwardl
          </p>
          <a class="thm-btn yellow-bg" routerLink="/about" style="padding: 5px 15px;">Read More</a>
        </div>
      </div>
      <!--End single tab content-->
      <!--Start single tab content-->
      <div class="inner-box tab-pane fade active" id="csr">
        <div class="sec-title">
          <h1>CSR</h1>
        </div>
        <div class="text-box">
          <P>
            We at Hamco do not believe in the one-dimensional business layout. Focussed as we may be on
            our turnovers and profits, we do not neglect our social accountability in the process. Such
            culpability in not only restricted to us and our customers but extends further to include
            our stakeholders, the environment and the society at large as well. With the aim of never
            straying from our firm beliefs in this regard, Hamco Industries has brought within its
            spectrum two different bodies which work towards enhancing the society and the environment.
          </P>
        </div>
      </div>
      <!--End single tab content-->
      <!--Start single tab content-->
      <div class="inner-box tab-pane fade active" id="charity">
        <div class="sec-title">
          <p>Hamco</p>
          <h1>Charitable Trust</h1>
        </div>
        <div class="text-box">
          <p>
            An organisation directed towards promoting education to under privileged, the Hamco
            Charitable Trust brings significant concerns like plantation under its range as well.
          </p>
        </div>
      </div>
      <!--End single tab content-->
      <!--Start single tab content-->
      <div class="inner-box tab-pane fade active" id="foundation">
        <div class="sec-title">
          <p>Hamco</p>
          <h1>Foundation</h1>
        </div>
        <div class="text-box">
          <p>
            The education today is the foundation of tomorrow; realising the importance of education, we
            have the Hamco Foundation which works towards providing scholarships to poor children.
          </p>
        </div>
      </div>
      <!--End single tab content-->
    </div>
  </div>
</section>
<!--End about us area-->
