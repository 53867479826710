<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>Rolled Bars</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="">Rolled Bars</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li><a routerLink="/products">Products</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li>Rolled Bars</li>
      </ul>
    </div>
  </div>
</section>

<section id="project-area" class="project-single-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="project-single-carousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner" role="listbox">
            <div class="item active">
              <div class="single-item text-center">
                <div class="img-holder ">
                  <img src="./assets/img/products/Roll-bars.jpg" alt="Awesome Image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title-two">
            <h2>Rolled Bars</h2>
            <span class="border"></span>
          </div>
          <div>
            <b class="text-dark">
              Round:
              <hr>
            </b>
            20mm Dia to 75mm Dia: our products find a wide application in fasteners, forgings,
            engineering, and various other sectors. <br> With available size differences as low as 1mm.
            <br> eg. 19,20,21,22 mm etc. stocked in almost all widely used grades such as MS, EN, SAE
            series, etc.
          </div>
          <div class="pt-4">
            <b class="text-dark">
              Hexagon:
              <hr>
            </b>
            we are engaged in manufacturing of a wide range of Hex bars in various thickness and
            dimensions.
            <br> These are offered in various forms such as COLD FINISH Bright, HOT ROLLED black with
            ultrasonic testing from diameter 21mm to 37mm.
          </div>
          <div class="pt-4">
            <b class="text-dark">
              Flats:
              <hr>
            </b>
            65mmX 65mm X 6mm to 150mm X 150 mm X 12mm: we strive to consistently supply the right
            quality steel to satisfy individual customer requirements. <br> Looking ahead, a key part of
            our strategy is to focus on developing higher quality and premium range products of the
            entire industry at the most economical prices
          </div>
          <div class="pt-4">
            <b class="text-dark">
              Square :
              <hr>
            </b>
            25mm to 75mm: The steel produced conforms to multifarious grades falling under various
            Indian Standards such <br> as I.S., I.R.S. (Indian), BSS (British), DIN (German), GOST
            (Russian), AISI/SAE/ASTM (American), JIS (Japan), etc. as well as our customer’s own
            specification.

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
