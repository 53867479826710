import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-carrer',
  templateUrl: './carrer.component.html',
  styleUrls: ['./carrer.component.css']
})
export class CarrerComponent implements OnInit {

  carrerForm: FormGroup;
  submitted = false;
  selected_file: any = null;
  constructor(private fb: FormBuilder, private common: CommonService) {
    this.carrerForm = fb.group({
      name: [null, Validators.required],
      gender: [null, Validators.required],
      dob: [null],
      qualification: [null],
      contact: [null, Validators.required],
      email: [null],
      nationality: [null],
      current_job_title: [null],
      experience: [null],
    })
  }

  ngOnInit() {}

  fileChangeEvent(event) {
    this.selected_file = event.target.files[0];
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.carrerForm.invalid) {
      return;
    }

    let data = new FormData();
    data.append("image", this.selected_file);
    data.append("name", this.carrerForm.value.name);
    data.append("gender", this.carrerForm.value.name);
    data.append("dob", this.carrerForm.value.name);
    data.append("qualification", this.carrerForm.value.name);
    data.append("contact", this.carrerForm.value.name);
    data.append("email", this.carrerForm.value.name);
    data.append("nationality", this.carrerForm.value.name);
    data.append("current_job_title", this.carrerForm.value.name);
    data.append("experience", this.carrerForm.value.name);

    this.common.httpRequest('carrerEnquiry', data)
      .subscribe(data => {
        this.common.showSuccess("Enquiry sent successfully", "Success");
        this.carrerForm.reset();
        this.selected_file = null;
      }, error => {
        this.common.showError("Error while sending enquiry", "Error")
      })
  }

}
