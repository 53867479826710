import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProcessComponent } from './process/process.component';
import { BuySellComponent } from './buy-sell/buy-sell.component';
import { ProductsComponent } from './products/products.component';
import { CarrerComponent } from './carrer/carrer.component';
import { RollComponent } from './roll/roll.component';
import { SteelComponent } from './steel/steel.component';
import { BrightComponent } from './bright/bright.component';
import { StandardsComponent } from './standards/standards.component';
import { LibraryComponent } from './library/library.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'process', component: ProcessComponent },
  { path: 'buy-sell', component: BuySellComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'standards', component: StandardsComponent },
  { path: 'carrer', component: CarrerComponent },
  { path: 'library', component: LibraryComponent },
  { path: 'steel-ingots', component: SteelComponent },
  { path: 'rolled-bars', component: RollComponent },
  { path: 'bright-bars', component: BrightComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
