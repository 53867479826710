<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>Bright Bars</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="">Bright Bars</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li><a routerLink="/products">Products</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li>Bright Bars</li>
      </ul>
    </div>
  </div>
</section>

<section id="project-area" class="project-single-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="project-single-carousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner" role="listbox">
            <div class="item active">
              <div class="single-item text-center">
                <div class="img-holder ">
                  <img src="./assets/img/products/Bright-bars.jpg" alt="Awesome Image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title-two">
            <h2>Bright Bars</h2>
            <span class="border"></span>
          </div>
          <div>
            21mm to 50mm
            <br>
            Our bright steel bars are produced from Hot Rolled Bars/ Wire Rod Coils through the process
            of cold drawing, centreless grinding, peeling, shaving, turning or a combination of these
            processes to produce Bright Steel Bars with greater dimensional accuracy, straightness,
            smooth surface, free from surface defects and enhanced mechanical properties.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>