import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    if ($('.rev_slider_wrapper #slider1').length) {
      $('#slider1').revolution({
        sliderType: 'standard',
        sliderLayout: 'auto',
        delay: 6000,
        hideTimerBar: 'off',
        onHoverStop: 'off',
        navigation: {
          arrows: { enable: true }
        },
        gridwidth: 1170,
        gridheight: 580
      });
    }
  }

}
