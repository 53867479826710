import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-buy-sell',
  templateUrl: './buy-sell.component.html',
  styleUrls: ['./buy-sell.component.css']
})
export class BuySellComponent implements OnInit {
  buysell_form : FormGroup;
  submitted = false;
  constructor(fb: FormBuilder, private common: CommonService) {
    this.buysell_form = fb.group({
      firm_name: [null,Validators.required],
      phone_number: [null,Validators.required],
      email_id: [null],
      buy_description: [null],
      sell_description: [null],
    })
  }

  ngOnInit() {
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.buysell_form.invalid) {
      return;
    }
    this.common.httpRequest('buySellEnquiry',this.buysell_form.value)
    .subscribe(
      data => {
        this.common.showSuccess("Enquiry sent successfully","Success");
        this.buysell_form.reset();
      },
      error => {
        this.common.showError("Error while sending enquiry", "Error")
      }
    )
  }

}
