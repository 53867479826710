<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
    <div class="container text-center">
        <h1>Process</h1>
    </div>
</section>

<section class="breadcrumb-bottom-area">
    <div class="container">
        <div class="left pull-left">
            <a routerLink="contact ">Process</a>
        </div>
        <div class="right pull-right">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                <li>Process</li>
            </ul>
        </div>
    </div>
</section>

<section class="process-section mt-5 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <p>Process</p>
                    <h1>Quality and Infrastructure</h1>
                </div>


                <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Procuring raw materials</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/alloying.jpg" alt=""> -->
                            <img src="./assets/img/process/Procuring raw material.jpeg" alt="">

                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>We procure industrial scraps like sulphur and phosphorus wherein the impurities are found in
                            minimal quantity. In such scraps, unwanted elements are either absent or are present in
                            tolerant proportions. This in turn, results in better quality of final products.</p>
                    </div>
                </div>








                <!-- <div class="processlisting">
                    <div class="col-md-6 col-lg-12">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Procuring raw materials</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/Procuring raw material.jpeg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>We procure industrial scraps like sulphur and phosphorus wherein the impurities are found in
                            minimal quantity. In such scraps, unwanted elements are either absent or are present in
                            tolerant proportions. This in turn, results in better quality of final products.</p>
                    </div>
                </div> -->

                <!-- for mobile -->
                <div class="processlisting mob">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span> Alloying & Melting</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/alloying.jpg" alt="">
                        </div>

                    </div>
                    <div class="col-md-6">
                        <p>Additional alloys required as per the specific grade being manufactured are added into the
                            liquid metal such as
                            silico-manganese, silicon, ferrochromium, ferromanganese, nickel, and boron.
                            A proper mix of suitable scraps is fed to the induction furnace wherein the
                            chemical composition of the liquid metal is spectrographically checked at various
                            levels of melting. This leads to a perfect composition required for the
                            manufacturing of specific grades of steel.</p>
                    </div>

                </div>

                <div class="processlisting desk">
                    <div class="col-md-6">
                        <p>
                            Additional alloys required as per the specific grade being manufactured are added into the
                            liquid metal such as
                            silico-manganese, silicon, ferrochromium, ferromanganese, nickel, and boron.
                            A proper mix of suitable scraps is fed to the induction furnace wherein the
                            chemical composition of the liquid metal is spectrographically checked at various
                            levels of melting. This leads to a perfect composition required for the
                            manufacturing of specific grades of steel.</p>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/alloying.jpg" alt="">
                        </div>
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span> Alloying & Melting</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Degasification</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/alloying.jpg" alt=""> -->
                            <img src="./assets/img/process/degasification.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>High-quality aluminium bars are purged into liquid metal using a notch bar method. This helps
                            in the removal of
                            excessive gases from the metal.</p>
                    </div>
                </div>
                <!-- for mobile -->
                <div class="processlisting mob">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Temperature</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/degasification.jpg" alt=""> -->
                            <img src="./assets/img/process/temperature.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>Before transferring the liquid metal into the ladle, the specific temperature of the liquid
                            metal is attained using
                            temperature measurement meter thermocouple.</p>
                    </div>
                </div>
                <div class="processlisting desk">
                    <div class="col-md-6">
                        <p>Before transferring the liquid metal into the ladle, the specific temperature of the liquid
                            metal is attained using
                            temperature measurement meter thermocouple.</p>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/degasification.jpg" alt=""> -->
                            <img src="./assets/img/process/temperature.jpg" alt="">
                        </div>
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Temperature</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Refining and Pouring</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/temperature.jpg" alt=""> -->
                            <img src="./assets/img/process/Refining & Pouring.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>Before pouring the liquid metal into the ingot moulds, it is purged with argon gas which
                            leads to degasification of remaining gases in the metal. Further, a shield of argon gas is
                            created around the metal being poured into the ingot moulds (of various sizes) to avoid
                            merger of atmospheric oxygen into the metal.
                            <br />
                            A teeming compound is also used in the moulds which when in contact with the liquid metal,
                            creates a thin layer of gas between the mould and the liquid metal being poured, so as to
                            attain a better surface of the steel ingots. Furthermore, exothermic hot tops are affixed on
                            the top of the moulds which help avoid the piping defects in steel ingots.
                        </p>
                    </div>
                </div>
                <!-- for mobile -->
                <div class="processlisting mob">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Refractory</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/Refraactory.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p> High Alumina refractory is used to reduce erosion and inclusion of refractories in the
                            liquid metal. This helps in ensuring good quality steel ingots and other final products.
                        </p>
                    </div>
                </div>
                <div class="processlisting desk">
                    <div class="col-md-6">
                        <p> High Alumina refractory is used to reduce erosion and inclusion of refractories in the
                            liquid metal. This helps in ensuring good quality steel ingots and other final products.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/refining-and-pouring.jpg" alt=""> -->
                            <img src="./assets/img/process/Refraactory.jpg" alt="">
                        </div>
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Refractory</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Solidification</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/refractory.jpg" alt=""> -->
                            <img src="./assets/img/process/solidification.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>
                            Once the poured liquid solidifies in the moulds, the ingots are then de-moulded and
                            transferred to the ingot yard.</p>
                    </div>
                </div>
                <!-- for mobile -->
                <div class="processlisting mob">
                    <div class="col-md-6 p-0">
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Final testing and colour coding</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <!-- <img src="./assets/img/process/solidification.jpg" alt=""> -->
                            <img src="./assets/img/process/Color Coding.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>High Alumina refractory is used to reduce erosion and inclusion of refractories in the liquid
                            metal takes
                            place in
                            this
                            step. This helps in ensuring good quality steel ingots and other final products.</p>
                    </div>
                </div>
                <!-- for desktop  -->
                <div class="processlisting desk">
                    <div class="col-md-6">
                        <p>Final testing is again conducted spectrographically to ensure that specific chemical
                            compositions are accurate as per the various grades of steel ingots. Once ensured for
                            quality, these steel ingots are colour coded for different grades.</p>
                    </div>
                    <div class="col-md-6 p-0">
                        <div class="col-md-10">
                            <!-- <img src="./assets/img/process/solidification.jpg" alt=""> -->
                            <img src="./assets/img/process/Color Coding.jpg" alt="">
                        </div>
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Final testing and colour coding</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Grinding</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <!-- <img src="./assets/img/process/testing-and-colour-coding.jpg" alt=""> -->
                            <img src="./assets/img/process/Grinding.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>The surface of the ingots manufactured is now ground using swing grinders. This helps in
                            maintaining a high quality of
                            the final product.</p>
                    </div>
                </div>
                <!-- for mobile -->
                <div class="processlisting mob">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Stacking</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/stacking.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>Next, the ingots so produced are stacked in the ingot yard.</p>
                    </div>
                </div>
                <!-- for desktop -->
                <div class="processlisting desk">
                    <div class="col-md-6">
                        <p>Next, the ingots so produced are stacked in the ingot yard.</p>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/mstacking.jpg" alt="">
                        </div>
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Stacking</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Stacking</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/stacking.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>Next, the ingots so produced are stacked in the ingot yard.</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>

<section class="process-section mt-5 mb-5" style="padding-top: 15px;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <h1>Rolling</h1>
                </div>
                <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Preheating</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/mPreheating.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>Steel ingots are fed into the reheating furnace where the ingots are preheated at a
                            temperature of around 1100
                            degree
                            Celsius (depending on the grade of the ingot). Proper heating and soaking time is given to
                            the ingots before the
                            start
                            of rolling.</p>
                    </div>
                </div>
                <!-- for mobile -->
                <div class="processlisting mob">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Roughing</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/about/Rolling.jpg" alt="">
                        </div>

                    </div>
                    <div class="col-md-6">
                        <p>The preheated ingots are then fed into the roughing mill where adequate pressure is exerted
                            on the ingots for
                            roughing.</p>
                    </div>

                </div>
                <!-- for desktop -->
                <div class="processlisting desk">
                    <div class="col-md-6">
                        <p>The preheated ingots are then fed into the roughing mill where adequate pressure is exerted
                            on the ingots for
                            roughing.</p>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/about/Rolling.jpg" alt="">
                        </div>
                        <div class="col-md-2 p-0">
                            <div class="subheading2">
                                <h2 class="processsubheading2">
                                    <span>Roughing</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="processlisting">
                    <div class="col-md-6">
                        <div class="col-md-2 p-0">
                            <div class="subheading">
                                <h2 class="processsubheading">
                                    <span>Intermediate and finishing mill</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-10 p-0">
                            <img src="./assets/img/process/Intermediate Mill.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>Now the goods under process are shifted to the intermediate mill and thereafter to the
                            finishing mill. Once in
                            the
                            finishing mill, the rolled bars are checked regularly for accurate size and tolerance. After
                            rolling, the bars/
                            flats
                            are placed on the cooling bed, where the rolled products are left to cool down. Thereafter,
                            the loose ends are
                            cut and
                            finally, the products are sliced to the standard length (or as per the requirement of the
                            customer).</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section class="process-section mt-5 mb-5" style="padding-top: 15px;">
    <div class="container desk">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <h1>Testing &amp; Inspection</h1>
                </div>
                <div class="pt-3">
                    <div class="processlisting desk">
                        <div class="col-md-6" style="margin-top: -54px;">
                            <p>During rolling various tests are conducted such as</p>
                            <ul style="list-style: none;padding-left: 0; margin-top: 26px;">
                                <li><i class="fa fa-circle mr-3"></i> Tensile strength test</li>
                                <li><i class="fa fa-circle mr-3"></i> Step down test</li>
                                <li><i class="fa fa-circle mr-3"></i> Bend test</li>
                                <li><i class="fa fa-circle mr-3"></i> Structure test</li>
                                <li><i class="fa fa-circle mr-3"></i> Inclusion rating</li>
                                <li><i class="fa fa-circle mr-3"></i> Ultrasound</li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <div class="col-md-10 p-0">
                                <img src="./assets/img/process/Testing.jpg" alt="" style="width: 224px; height: 224px;">
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 w-100">

                </div> -->
                    <!-- <div class="col-md-6">
                    <img src="./assets/img/about/Rolling.jpg" alt="" style="width: 250px; height: 250px; border-radius: 40px;">
                </div> -->
                    <p>To ensure the quality of the finished good, each and every finished product goes through
                        prearranged quality checks. These checks are conducted through non-destructive testing
                        equipment.</p>
                </div>

            </div>
        </div>
    </div>

    <div class="container mob">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <h1>Testing &amp; Inspection</h1>
                </div>
                <div class="pt-3">
                    <div class="processlisting mob">
                        <div class="col-md-6" style="margin-top: -54px;">
                            <p>During rolling various tests are conducted such as</p>
                            <ul style="list-style: none;padding-left: 0; margin-top: 26px;">
                                <li><i class="fa fa-circle mr-3"></i> Tensile strength test</li>
                                <li><i class="fa fa-circle mr-3"></i> Step down test</li>
                                <li><i class="fa fa-circle mr-3"></i> Bend test</li>
                                <li><i class="fa fa-circle mr-3"></i> Structure test</li>
                                <li><i class="fa fa-circle mr-3"></i> Inclusion rating</li>
                                <li><i class="fa fa-circle mr-3"></i> Ultrasound</li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <div class="col-md-10 p-0">
                                <img src="./assets/img/process/Testing.jpg" alt="" style="width: 224px; height: 224px;">
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 w-100">

              </div> -->
                    <!-- <div class="col-md-6">
                  <img src="./assets/img/about/Rolling.jpg" alt="" style="width: 250px; height: 250px; border-radius: 40px;">
              </div> -->
                    <p>To ensure the quality of the finished good, each and every finished product goes through
                        prearranged quality checks. These checks are conducted through non-destructive testing
                        equipment.</p>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="process-section mt-5 mb-5" style="padding-top: 15px;">
    <div class="container mob">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <h1>Colour Coding</h1>
                </div>
                <div class="pt-3">
                    <div class="processlisting mob">
                        <div class="col-md-6" style="margin-top: -54px;">
                            <p>Finished goods are colour coded as per the grade of the steel</p>
                        </div>
                        <div class="col-md-6">
                            <div class="col-md-10 p-0">
                                <img src="./assets/img/process/Color Coding.jpg" alt=""
                                    style="width: 224px; height: 224px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container desk">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <h1>Colour Coding</h1>
                </div>
                <div class="pt-3">
                    <div class="processlisting desk">
                        <div class="col-md-6" style="margin-top: -54px;">
                            <p>Finished goods are colour coded as per the grade of the steel</p>
                        </div>
                        <div class="col-md-6">
                            <div class="col-md-10 p-0">
                                <img src="./assets/img/process/Color Coding.jpg" alt=""
                                    style="width: 224px; height: 224px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="process-section mt-5 mb-5" style="padding-top: 15px;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <h1>Stacking and Dispatch</h1>
                </div>
                <div class="pt-3">
                    <p>Rolled products are stacked in the finished goods yard and then later dispatched as per the
                        requirement of the
                        customers.</p>
                </div>
            </div>
        </div>
    </div>
</section>
