<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>Contact</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="contact ">Contact</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li>Contact</li>
      </ul>
    </div>
  </div>
</section>

<section class="contact-area">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-7 col-xs-12">
        <div class="contact-details">
          <h2>Contact Details</h2>
          <div class="contact-info">
            <div class="item">
              <ul class="contact-info">
                <li>
                  <div class="icon-box">
                    <i class="fa fa-map"></i>
                  </div>
                  <div class="text-box">
                    <span class="text-dark">Address:</span>
                      <p>Hamco Industries Pvt. Ltd.
                          <br/>
                        G.T. Road Maksudan Jalandhar, 144008.</p>
                      <hr style="margin:5px 0"> 
                      <p>Hamco Power Ventures Pvt. Ltd. 
                          <br/>
                        Pathankot Road,Jalandhar, 144012</p>
                  </div>
                </li>
                <li>
                  <div class="icon-box">
                    <i class="fa fa-phone"></i>
                  </div>
                  <div class="text-box">
                    <p>
                        <span class="text-dark">Call Us:</span>
                      0181-2708147
                    </p>
                  </div>
                </li>
                <li>
                  <div class="icon-box">
                    <i class="fa fa-envelope"></i>
                  </div>
                  <div class="text-box">
                    <p>
                      <span class="text-dark">
                        Mail Us:
                      </span>
                      <a href="mailto:hamcoindia@yahoo.com">
                        hamcoindia@yahoo.com
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-sm-12 col-xs-12">
        <div class="send-message-form">
          <div class="title">
            <h2>Message Us</h2>
            <span class="border"></span>
          </div>
          <h3>I would like to discuss:</h3>
          <form  [formGroup]="contactform" (ngSubmit)="submit()" class="contact-form">
            <div class="row">
              <div class="col-md-6">
                <input type="text" formControlName="first_name"  name="first_name" placeholder="First Name *" [ngClass]="{ 'is-invalid': submitted && contactform.controls.first_name.errors }">
                <p class="text-danger"
                  *ngIf="(contactform.controls.first_name.errors?.required && submitted) || (contactform.controls.first_name.errors?.required && contactform.controls.first_name.touched)">
                  First name is required</p>
              </div>
              <div class="col-md-6">
                <input type="text" formControlName="last_name" name="last_name" placeholder="Last Name *" [ngClass]="{ 'is-invalid': submitted && contactform.controls.last_name.errors }">
                <p class="text-danger"
                  *ngIf="(contactform.controls.last_name.errors?.required && submitted) || (contactform.controls.last_name.errors?.required && contactform.controls.last_name.touched)">
                  Last name is required</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input type="text" formControlName="email" name="email" placeholder="Your E-mail*">
              </div>
              <div class="col-md-6">
                <input type="text" formControlName="phone_number" name="phone_number" placeholder="Phone Number" [ngClass]="{ 'is-invalid': submitted && contactform.controls.phone_number.errors }">
                <p class="text-danger"
                  *ngIf="(contactform.controls.phone_number.errors?.required && submitted) || (contactform.controls.phone_number.errors?.required && contactform.controls.phone_number.touched)">
                  Phone Number is required</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <textarea name="need" formControlName="need" placeholder="Write Your Needs..."></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button class="thm-btn yellow-bg" type="submit">Your Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>