<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
    <div class="container text-center">
        <h1>Standards</h1>
    </div>
</section>

<section class="breadcrumb-bottom-area">
    <div class="container">
        <div class="left pull-left">
            <a routerLink="contact ">Standards</a>
        </div>
        <div class="right pull-right">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                <li>Standards</li>
            </ul>
        </div>
    </div>
</section>
<section class="welcome-industry-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title">
            <h3>Grades being Manufactured<br/>
                  <p>they specify chemical composition</p>
            </h3>
          </div>
          <!-- <h3><strong>line added</strong>: ()</h3> -->
          <div class="">
            <h3>Carbon Steel (non alloy)</h3>
          </div>
          <div class="row">
            <div class="col-md-2">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-8D
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN8D-Cr
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-9
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    C-38
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    C-45
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="welcome-industry-area" style="padding-top: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="">
            <h3>Low Alloy Steel</h3>
          </div>
          <div class="row">
            <div class="col-md-2">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    16MnCr5
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    20MnCr5
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     SAE-8620
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     SUP-11
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-md-2">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     SAE-4140
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-15
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-18
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     16NiCr5
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-md-2">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-19
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-24
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     EN-31
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-md-2">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     EN-45
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-45A
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    EN-351
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-md-2">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     EN-353
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    31CrV3
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    SAE-1020
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-md-2">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                     SAE-1040
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Boron Steel
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    SUP-9
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <p class="processStacking">Materials with any Specific Requirements in relation to chemistry (Especially Railways) are also catered.</p>
        </div>
      </div>
    </div>

  </div>

</section>
