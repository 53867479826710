<footer class="footer-area">
  <div class="container">
    <div class="row">
      <!--Start single footer widget-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="single-footer-widget pd-bottom">
          <div class="footer-logo">
            <a routerLink="/">
              <img src="./assets/img/footer/footer-logo.png" width="75" alt="Hamco Logo"> <span>Hamco Industries</span>
            </a>
          </div>
          <div class="industry-info">
            <p>
              Hamco Industries Pvt. Ltd., the name that you know today has a rich and colorful
              past, all of which has helped us become the institute we are today. Hamco Group of
              Companies was founded by Late Sh. Om Prakash Gupta in the year 1952.
            </p>
          </div>
        </div>
      </div>
      <!--End single footer widget-->
      <!--Start single footer widget-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="single-footer-widget pd-bottom">
          <div class="title">
            <h3>Quick Links</h3>
          </div>
          <ul class="quick-links left">
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="/about">About</a></li>
            <li><a routerLink="/products">Products</a></li>
            <li><a routerLink="/process">Process</a></li>
          </ul>
          <ul class="quick-links left">
            <li><a routerLink="/buy-sell">Buy And Sell</a></li>
            <li><a routerLink="/carrer">Career</a></li>
            <li><a routerLink="/contact">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <!--End single footer widget-->
      <!--Start single footer widget-->
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="single-footer-widget">
          <div class="title">
            <h3>Get In Touch</h3>
          </div>
          <ul class="contact-address">
            <li>
              <div class="content-holder">
                <p><span>Address:</span> Hamco Industries Pvt. Ltd. G.T. Road Maksudan Jalandhar, 144008.</p>
              </div>
            </li>
            <li>
              <div class="content-holder">
                <p><span>Call Us:</span> 0181-2708147</p>
              </div>
            </li>
            <li>
              <div class="content-holder">
                <p><span>Mail Us:</span> hamcoindia@yahoo.com</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--End single footer widget-->
    </div>
  </div>
</footer>

<section class="footer-bottom-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-6 col-sm-12 col-xs-12">
        <div class="copyright-text ">
          <p>Copyright © 2019 Hamco Industries</p>
        </div>
      </div>
      <!-- <div class="col-lg-7 col-md-6 col-sm-12 col-xs-12">
        <ul class="footer-menu">
          <li><a routerLink="/">Home</a></li>
          <li><a routerLink="/about">About</a></li>
          <li><a routerLink="/products">Products</a></li>
          <li><a routerLink="/buy-sell">Buy And Sell</a></li>
          <li><a routerLink="/process">Process</a></li>
          <li><a routerLink="/carrer">Career</a></li>
          <li><a routerLink="/contact">Contact</a></li>
        </ul>
      </div> -->
    </div>
  </div>
</section>
