<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>About Us</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="/about">About Us</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li>About Us</li>
      </ul>
    </div>
  </div>
</section>

<section class="welcome-industry-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title">
            <h1>About Us</h1>
          </div>
          <p>
            Hamco Group of Companies was founded by Late Sh. Om Prakash Gupta in the year 1952. The initial business profile of Hamco entailed manufacturing of Fasteners which were mostly imported at the time. As the business grew, Hind Auto Manufacturing Co. was established in the year 1956.
          </p>
          <p>
            Now the need was to enhance future expansions of the business through forward integration. Thus, Hamco Industries Private Limited came into being in 1965.
          </p>
          <p>
            As the business grew further, timely availability of quality raw material had to be ensured therefore, the company decided to go for backward integration as well. A Re-Rolling mill was installed in the year 1976 for manufacturing steel bars for industrial use. The quality of the industrial rounds so manufactured was widely appreciated exhibiting quite a promise in that industry as well. Hence in addition to the captive consumption, the company also started selling the industrial rounds in the market for Fastener Manufacturing.

          </p>
          <p>
            With successful and ethical business practices in tow, Hamco Industries flourished, and eventually joined hands with the Steel Authority of India Limited in 1991. With this deal, Hamco Industries became conversion agents for SAIL for the Re-Rolling of C.T.D. Bars, Rounds and Structures.
          </p>
          <p>
            Delving deeper for better backward integration, a steel casting unit in the name of Punjab Castings Private Limited was taken over by the Group in the year 1998. This unit was responsible for the manufacturing of various Non-Alloy and Alloy steels and was renamed to be Hamco Power Ventures Private Limited.
          </p>
          <p>
          It is with such exuberant nurturing that the Hamco you know today was bred
          </p>
          <p>
            Today Hamco has a name that resonates with quality. Our supply of products which are both exceptionally high in quality and reasonably priced for our consumers is our primary reason for growth.
          </p>
          <p>
            Finally, our aim is to continue along this ascending graph which would finally result in the upliftment of our local industry, our employees, our society and our environment.
          </p>
          <div class="about-cycle-img pt-3">
            <img src="./assets/img/about/timeline.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="welcome-industry-area" style="padding-top: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title">
            <p>Company</p>
            <h1>INTEGRITY</h1>
          </div>
          <p>
            At Hamco, integrity is at the core of every relationship. We treat our employees, customers, partners, and suppliers with respect and fairness. Not only do we imbibe this in our practices but we expect the same in return. From day one, our employees are instructed to act ethically in all business dealings. For us, integrity is not a virtue which is commonly adhered to rather it is ritual which is practiced ALWAYS.
          </p>
          <p>
            Integrity is one of our founding principles and we believe in:
          </p>
          <ul class="categories clearfix">
            <li class="pb-2">
              <i class="fa fa-circle mr-3"></i>
              <span class="ml-2">
                Doing the right thing for the right reasons, even when it may be more convenient or advantageous to take another approach.
              </span>
            </li>
            <li class="pb-2">
              <i class="fa fa-circle mr-3"></i>
              <span class="ml-2">
                Honouring and fulfilling our commitments and agreements as responsible citizens and employers.
              </span>
            </li>
            <li class="pb-2">
              <i class="fa fa-circle mr-3"></i>
              <span class="ml-2">
                Being honest, straightforward and transparent in our communications at all times.
              </span>
            </li>
            <li class="pb-2">
              <i class="fa fa-circle mr-3"></i>
              <span class="ml-2">
                Doing what we claim we are going to do.
              </span>
            </li>
            <li class="pb-2">
              <i class="fa fa-circle mr-3"></i>
              <span class="ml-2">
                Only working with honest companies who are equally dedicated to doing business in an ethical manner.
              </span>
            </li>
            <li class="pb-2">
              <i class="fa fa-circle mr-3"></i>
              <span class="ml-2">
                If in case there are any mistakes, acknowledging them, learning from them and not repeating them.
              </span>
            </li>
            <li class="pb-2">
              <i class="fa fa-circle mr-3"></i>
              <span class="ml-2">
                Never compromising on our values.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="welcome-industry-area" style="padding-top: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title">
            <p>Our</p>
            <h1>CSR</h1>
          </div>
          <p>
            We at Hamco do not believe in the one-dimensional business layout. While turnover and profits may be a business focus, we do not neglect our social responsibility in the process. Such accountability is not only restricted to us and our customers but extends further to include our stakeholders, the environment and the society at large as well. With the aim of never straying from our firm beliefs in this regard, Hamco Industries has brought within its spectrum two different bodies which work towards advancement of the society and the environment:
          </p>
          <div class="row">
            <div class="col-md-6">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2" style="font-size:17px; color:#00a">
                    Hamco Charitable Trust
                  </span><br/>
                  <p>
                    An organisation directed towards promoting education for the under privileged, the Hamco Charitable Trust also brings significant concerns like plantation under its purview as well.
                  </p>
                </li>
               </ul>
             </div>
              <div class="col-md-6">
                <ul class="categories clearfix">
                  <li class="pb-2">
                    <i class="fa fa-circle mr-3"></i>
                    <span class="ml-2" style="font-size:17px; color:#00a">
                      Foundation
                    </span><br/>
                    <p>
                      Education today is tomorrow’s foundation; hence we have the Hamco Foundation which works towards providing scholarships to under privileged children.
                    </p>
                  </li>
                  </ul>
                </div>
            <!-- <div class="col-md-6">
              <div class="sec-title">
                <p>Hamco</p>
                <h1>Charitable Trust</h1>
              </div>
              <p>
                An organisation directed towards promoting education to under privileged, the Hamco
                Charitable Trust brings significant concerns like plantation under its range as well.
              </p>
            </div>
            <div class="col-md-6">
              <div class="sec-title">
                <p>Hamco</p>
                <h1>Foundation</h1>
              </div>
              <p>
                The education today is the foundation of tomorrow; realising the importance of education, we
                have the Hamco Foundation which works towards providing scholarships to poor children.
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="welcome-industry-area" style="padding-top: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title">
            <p>Our</p>
            <h1>Associates</h1>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Gitansh International Private Limited, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Ashirwad Strips Private Limited, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Ajar Amar Steel, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    R.P. Alloys & Forgings, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Gaurish Steels Private Limited, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Star Steel Industries, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Ranbir Steel Industries, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Krishna Steel Rolling Mills, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Bhoday Steel Rolling Mills, Mondi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Sharu Industries Private Limited, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">Sher E Punjab Steel & Agro Industries, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Vital Steels Bars LLP, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    T.K. Steel Rolling Mills Private Limited, Ludhiana
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="categories clearfix">
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    R. K. Plate Re-Rolling Mills, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    A.H. Alloys, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Bedi Steels Rolling Mills Private Limited, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Dhiman Industries Private Limited, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Lord Mahaveer Industries Private Limited, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Ludhiana Steel Rolling Mills, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Paramount Steels Limited, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Paviter Metals Private Limited, Ludhiana
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Quality Multimetals Private Limited, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Quality Steels, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Rajasthan Iron Traders, Mandi Gobindgarh
                  </span>
                </li>
                <li class="pb-2">
                  <i class="fa fa-circle mr-3"></i>
                  <span class="ml-2">
                    Rajasthan Steel & Agro Industries, Mandi Gobindgarh
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="welcome-industry-area" style="padding-top: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <div class="sec-title">
            <p>Our</p>
            <h1>Certificates</h1>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 mb-3">
            <img src="./assets/img/about/certificate1.jpeg" alt="">
          </div>
          <div class="col-md-6">
            <img src="./assets/img/about/certificate2.jpeg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
