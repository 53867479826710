import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactform: FormGroup;
  submitted = false;
  constructor(private fb: FormBuilder, private common: CommonService) {
    this.contactform = fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null],
      phone_number: [null, Validators.required],
      need: [null],
    })
  }

  ngOnInit() {
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactform.invalid) {
      return;
    }
    this.common.httpRequest('contactEnquiry', this.contactform.value)
      .subscribe(
        data => {
          this.common.showSuccess("Enquiry sent successfully", "Success");
          this.contactform.reset();
        },
        error => {
          this.common.showError("Error while sending enquiry", "Error")
        }
      )
  }

}
