import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private headers: any;
  private _base_url = environment.baseUrl;

  constructor(private toastr: ToastrService, private http: HttpClient) { }

  getBaseUrl() {
    return this._base_url;
  }

  httpRequest(url, data): Observable<any> {
    let p_url = this.getBaseUrl() + "v1/" + url;
    return this.http.post(p_url, data)
  }

  showSuccess(msg, title) {
    this.toastr.success(msg, title, { timeOut: 3000, positionClass: 'toast-top-right', })
  }

  showError(msg, title) {
    this.toastr.error(msg, title, { timeOut: 3000, positionClass: 'toast-top-right', })
  }

}
