<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>Steel Ingots</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="">Steel Ingots</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li><a routerLink="/products">Products</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li>Steel Ingots</li>
      </ul>
    </div>
  </div>
</section>

<section id="project-area" class="project-single-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="project-single-carousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner" role="listbox">
            <div class="item active">
              <div class="single-item text-center">
                <div class="img-holder ">
                  <img src="./assets/img/products/Steel-ingot.jpg" alt="Steel-ingot">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="content">
          <div class="sec-title-two">
            <h2>Steel Ingots</h2>
            <span class="border"></span>
          </div>
          <p>
            Steel Ingots in section 3.5”x 4.5”; 4.5”x 5.0” and 5.5” x 6.5”
            <br>
            Round Shape Ingots in Dia 150 MM to 360 MM in D2, D3 or any other Grades

          </p>
        </div>
      </div>
    </div>
  </div>
</section>
