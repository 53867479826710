<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>Buy and Sell</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="">Buy and Sell</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li>Buy and Sell</li>
      </ul>
    </div>
  </div>
</section>

<section class="contact-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="send-message-form">
          <div class="title">
            <h2>Buy and Sell</h2>
            <span class="border"></span>
          </div>
          <h3>Please fill out form to continue :</h3>
          <form [formGroup]="buysell_form" (ngSubmit)="submit()" class="contact-form">
            <div class="row">
              <div class="col-md-6">
                <input type="text" name="firm" formControlName="firm_name" placeholder="Firm Name" [ngClass]="{ 'is-invalid': submitted && buysell_form.controls.firm_name.errors }">
                <!-- *ngIf="color.get('qtyToOrder').errors?.required && color.get('qtyToOrder').touched" -->
                <p class="text-danger" *ngIf="(buysell_form.controls.firm_name.errors?.required && submitted) || (buysell_form.controls.firm_name.errors?.required && buysell_form.controls.firm_name.touched)">
                  Firm Name is required</p>
              </div>
              <div class="col-md-6">
                <input type="text" name="email" formControlName="email_id" placeholder="Firm's E-mail Id">
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <input type="text"  name="phone" formControlName="phone_number" placeholder="Firm's Phone Number"  [ngClass]="{ 'is-invalid': submitted && buysell_form.controls.phone_number.errors }">
              </div>
              <p class="text-danger"
                *ngIf="(buysell_form.controls.phone_number.errors?.required && submitted) || (buysell_form.controls.phone_number.errors?.required && buysell_form.controls.phone_number.touched)">
                Phone Number is required</p>
            </div>
            <div class="row">
              <div class="col-md-12">
                <textarea name="buy" formControlName="buy_description" placeholder="Buyer's Description"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <textarea name="sell" formControlName="sell_description" placeholder= "Seller's Description"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 " style="margin-bottom:1rem">
                <button class="thm-btn yellow-bg" type="submit">Submit</button>
              </div>
              <div class="col-md-6">
                <button class="thm-btn yellow-bg" type="reset">Reset</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
