import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProductsComponent } from './products/products.component';
import { BuySellComponent } from './buy-sell/buy-sell.component';
import { ProcessComponent } from './process/process.component';
import { CarrerComponent } from './carrer/carrer.component';
import { SteelComponent } from './steel/steel.component';
import { RollComponent } from './roll/roll.component';
import { BrightComponent } from './bright/bright.component';
import { LibraryComponent } from './library/library.component';
import { StandardsComponent } from './standards/standards.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ProductsComponent,
    BuySellComponent,
    ProcessComponent,
    CarrerComponent,
    SteelComponent,
    RollComponent,
    BrightComponent,
    LibraryComponent,
    StandardsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
    }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
