<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>Our Products</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="/products ">Our Products</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/ ">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li> Our Products</li>
      </ul>
    </div>
  </div>
</section>

<section class="services-area solution-area">
  <div class="container">
    <div class="row">
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/Steel-ingot.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" data-toggle="modal" data-target="#productModal">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Steel Ingots</h3>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/Roll-bars.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" data-toggle="modal" data-target="#productModal1">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Rolled bars</h3>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/Bright-bars.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" data-toggle="modal" data-target="#productModal2">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Bright Bars</h3>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/flat-bar-rods.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" data-toggle="modal" data-target="#productModal3">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Flats</h3>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/hexagon-steel-rods.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" data-toggle="modal" data-target="#productModal4">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Hexagonal Rods</h3>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->

      <!--Start single service item-->
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="single-service-item">
          <div class="img-holder">
            <img src="./assets/img/products/Square-bars.jpg" alt="Awesome Image">
            <div class="overlay">
              <div class="box">
                <div class="content">
                  <a class="thm-btn yellow-bg" data-toggle="modal" data-target="#productModal6">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-holder pl-0">
            <div class="text pl-0">
              <h3>Square Bars</h3>
            </div>
          </div>
        </div>
      </div>
      <!--End single service item-->
    </div>
  </div>
</section>
<div class="modal fade" id="productModal" role="dialog" aria-labelledby="productModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Steel Ingots</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>Pencil Ingots</strong> in section 3.5”x 4.5”; 4.5”x 5.0” and 5.5” x 6.5”.<br>
        <strong>Round Shape Ingots</strong>  in diameter 150 mm to 360 mm in D2, D3 or any other grades.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="productModal1" role="dialog" aria-labelledby="productModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Rolled bars</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p><strong>Round</strong>: 20mm Dia to 75mm Dia<br/> Our products find a wide application in fasteners, forgings, engineering, and various other sectors. With available size differences as low as 1mm. eg. 19,20,21,22 mm etc. stocked in almost all widely used grades such as MS, EN, SAE series, etc.
       </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="productModal2" role="dialog" aria-labelledby="productModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Bright Bars</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p><strong>21mm to 50mm</strong><br/>
         Our bright steel bars are produced from Hot Rolled Bars/ Wire Rod Coils through the process of cold drawing, centreless grinding, peeling, shaving, turning or a combination of these processes to produce greater dimensional accuracy, straightness, smooth surface free from surface defects and enhanced mechanical properties.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="productModal3" role="dialog" aria-labelledby="productModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Flats</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p><strong>Flats</strong>:  65mmX 65mm X 6mm to 150mm X 150 mm X 12mm <br/>
          We strive to consistently supply the right quality steel to satisfy individual customer requirements. Looking ahead, a key part of our strategy is to focus on developing higher quality and premium range products of the entire industry at the most economical prices.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="productModal4" role="dialog" aria-labelledby="productModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Hexagonal Rods</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p>We are engaged in manufacturing a wide range of Hex bars in various dimensions. These are offered in various forms such as Cold Finished Bright, Hot Rolled Black etc. with ultrasonic testing from diameters 21mm to 37mm.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="productModal6" role="dialog" aria-labelledby="productModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Square Bars</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p><strong>Square</strong>: 25mm to 75mm <br/>
         The steel produced conforms to multifarious grades falling under various Indian Standards such as I.S., I.R.S. (Indian), BSS (British), DIN (German), GOST (Russian), AISI/SAE/ASTM (American), JIS (Japan), etc. as well as our customer’s own specifications.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
