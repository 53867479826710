<section class="breadcrumb-area" style="background-image: url(assets/img/breadcrumb/breadcrumb-bg.jpg);">
  <div class="container text-center">
    <h1>Career</h1>
  </div>
</section>

<section class="breadcrumb-bottom-area">
  <div class="container">
    <div class="left pull-left">
      <a routerLink="">Career</a>
    </div>
    <div class="right pull-right">
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><i class="fa fa-angle-right" aria-hidden="true"></i></li>
        <li>Career</li>
      </ul>
    </div>
  </div>
</section>

<section class="contact-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="send-message-form">
          <div class="title">
            <h2>Career</h2>
            <span class="border"></span>
          </div>
          <h3>Please fill out form to continue :</h3>
          <form  [formGroup]="carrerForm" (ngSubmit)="submit()" class="contact-form">
            <div class="row">
              <div class="col-md-6">
                <input type="text" name="name" formControlName="name" placeholder="Name">
              </div>
              <div class="col-md-6">
                <input type="text" name="gender" formControlName="gender" placeholder="Gender">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input type="text" name="date" formControlName="dob" placeholder="Date Of Birth">
              </div>
              <div class="col-md-6">
                <input type="text" name="qualification" formControlName="qualification" placeholder="Qualification">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input type="text" name="contact" formControlName="contact" placeholder="Contact Number">
              </div>
              <div class="col-md-6">
                <input type="text" name="email" formControlName="email" placeholder="E-Mail">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input type="text" name="nationality" formControlName="nationality" placeholder="Nationality">
              </div>
              <div class="col-md-6">
                <input type="text" name="current_job_title" formControlName="current_job_title" placeholder="Current Job Title">
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <input type="text" name="experience" formControlName="experience" placeholder="Experience">
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">Upload Your Resume</label>
                  <input type="file" class="filestyle " data-icon="false" (change)="fileChangeEvent($event)">
              </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <button class="thm-btn yellow-bg" type="submit">Submit</button>
              </div>
              <div class="col-md-6">
                <button class="thm-btn yellow-bg" type="reset">Reset</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
